import React, { useState, useEffect } from 'react';
import { ScrollText, Terminal, Twitter } from 'lucide-react';

const LandingPage = () => {
  const [titleVisible, setTitleVisible] = useState(false);
  const [subtitleVisible, setSubtitleVisible] = useState(false);
  const [linksVisible, setLinksVisible] = useState(false);
  const [socialVisible, setSocialVisible] = useState(false);

  useEffect(() => {
    setTitleVisible(true);
    setTimeout(() => setSubtitleVisible(true), 800);
    setTimeout(() => setLinksVisible(true), 1600);
    setTimeout(() => setSocialVisible(true), 2400);
  }, []);

  return (
    <div className="min-h-screen bg-black text-amber-500 flex flex-col items-center justify-center p-4">
      {/* Logo */}
      <div className={`mb-8 transition-opacity duration-1000 ${titleVisible ? 'opacity-100' : 'opacity-0'}`}>
        <img src="/logo.png" alt="Oracle of Delphi" className="w-24 h-24 md:w-32 md:h-32" />
      </div>

      {/* ASCII Art Title */}
      <pre className={`font-mono text-xs sm:text-sm md:text-base whitespace-pre select-none
        transition-opacity duration-1000 ${titleVisible ? 'opacity-100' : 'opacity-0'}`}>
{` ██████╗ ██████╗  █████╗  ██████╗██╗     ███████╗
██╔═══██╗██╔══██╗██╔══██╗██╔════╝██║     ██╔════╝
██║   ██║██████╔╝███████║██║     ██║     █████╗  
██║   ██║██╔══██╗██╔══██║██║     ██║     ██╔══╝  
╚██████╔╝██║  ██║██║  ██║╚██████╗███████╗███████╗
 ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚══════╝╚══════╝`}
      </pre>

      {/* Main Title */}
      <div className={`mt-8 flex flex-col items-center space-y-4 text-xl md:text-2xl
        text-purple-400 font-semibold transition-all duration-1000
        ${subtitleVisible ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform translate-y-4'}`}>
        <span>🔮 The Oracle of Delphi</span>
        <span className="text-base md:text-lg text-purple-300 font-normal italic">
          "Know thyself, and thou shalt know the universe"
        </span>
      </div>

      {/* Links Section */}
      <div className={`mt-12 flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8 
        transition-all duration-1000
        ${linksVisible ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform translate-y-4'}`}>
        <a 
          href="https://docs.oracle.fun" 
          target="_blank" 
          rel="noopener noreferrer"
          className="flex items-center space-x-2 text-amber-400 hover:text-amber-300 transition-colors duration-300"
        >
          <ScrollText size={24} />
          <span className="text-lg">Documentation</span>
        </a>
        
        <a 
          href="https://app.oracle.fun" 
          target="_blank" 
          rel="noopener noreferrer"
          className="flex items-center space-x-2 text-amber-400 hover:text-amber-300 transition-colors duration-300"
        >
          <Terminal size={24} />
          <span className="text-lg">App (Closed Beta)</span>
        </a>
      </div>

      {/* Social Links */}
      <div className={`mt-12 flex flex-col items-center space-y-4 transition-all duration-1000
        ${socialVisible ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform translate-y-4'}`}>
        <a 
          href="https://twitter.com/oracledotfun" 
          target="_blank" 
          rel="noopener noreferrer"
          className="flex items-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors duration-300"
        >
          <Twitter size={24} />
          <span className="text-lg">Follow @oracledotfun</span>
        </a>
      </div>

      {/* Decorative Footer Line */}
      <div className="mt-12 w-full max-w-3xl px-4">
        <div className="h-px bg-gradient-to-r from-transparent via-amber-500 to-transparent animate-pulse"></div>
      </div>
    </div>
  );
};

export default LandingPage;